import React, { useState } from "react"
import { Nav, Navbar, Dropdown } from "react-bootstrap"
import styled from "styled-components"
import StateContext from "../StateContext"
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom"
import { Row, Col } from "react-bootstrap"

// Multi - language support
import { useTranslation, Trans } from "react-i18next"
import FlagIcon from "./FlagIcon"

function NavigationBar() {
  const [countries] = useState([
    { code: "gb", title: "" },
    { code: "es", title: "" },
    { code: "fr", title: "" },
    { code: "pt", title: "" }
  ])

  //const [toggleContents, setToggleContents] = useState("Language")
  const [toggleContents, setToggleContents] = useState(<FlagIcon code={"gb"} />)

  const [selectedCountry, setSelectedCountry] = useState()

  const Styles = styled.div`
    .navbar {
      background-color: #222;
    }

    .navbar-brand,
    .navbar-nav .nav-link {
      color: #bbb;

      &:hover,
      &:focus,
      &:active {
        color: white;
      }
    }

    .navbar-toggler {
      padding: 0.25rem 0.75rem;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 2px solid gray;
      border-radius: 0.25rem;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='gray' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
  `

  // Select Language
  const { t, i18n } = useTranslation()

  const setAppLanguage = selectedCountry => {
    var selectedLanguage = selectedCountry
    if (selectedCountry == "GB" || selectedCountry == "US") {
      selectedLanguage = "EN"
    }

    setToggleContents(<FlagIcon code={selectedCountry} />)

    i18n.changeLanguage(selectedLanguage.toLowerCase())
  }

  return (
    <Styles>
      <Navbar expand="lg">
        <Navbar.Brand as={Link} to="/">
          <Row>
            <Col>Feelings Aveiro Apartment</Col>
          </Row>
        </Navbar.Brand>
        <Navbar.Text>
          <Row>
            <Col className="d-none d-sm-block">
              <span style={{ fontStyle: "italic", color: "grey" }}>{t("welcomeMessage")}</span>
            </Col>
          </Row>
        </Navbar.Text>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Nav.Link as={Link} to="/">
                {t("home")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/availability">
                {t("availability")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/photos">
                {t("photos")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/map">
                {t("map")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/contacts">
                {t("contact")}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Dropdown
                style={{ color: "#bbb" }}
                onSelect={eventKey => {
                  const { code, title } = countries.find(({ code }) => eventKey === code)
                  setAppLanguage(eventKey)
                }}
              >
                <Dropdown.Toggle variant="secondary" id="dropdown-flags" className="text-left" style={{ width: 50 }}>
                  {toggleContents}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {countries.map(({ code, title }) => (
                    <Dropdown.Item key={code} eventKey={code}>
                      <FlagIcon code={code} /> {title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  )
}

export default NavigationBar
