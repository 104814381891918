import React, { useEffect } from "react"
import "./index.css"
import aveiroimage from "./Assets/aveiroimage.jpg"
import cleanSafeLogo from "./Assets/cleanSafeLogo100.png"
import livroReclamacoesLogo from "./Assets/livroReclamacoesLogo100.png"
import airbnbLogo from "./Assets/airbnbLogo50.png"
import bookingLogo from "./Assets/bookingLogo50.png"
import { useTranslation, Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col } from "react-bootstrap"

function Home() {
  const { t, i18n } = useTranslation()

  return (
    <>
      <div className="admin-dashboard">
        <img src={aveiroimage} alt="Homepage" className="bg" />
      </div>
      <span style={{ fontSize: 12, color: "white", float: "right"}}>{t("policyNumber")}</span>

      <a href="http://www.livroreclamacoes.pt" style={{ float: "right", display: 'block', clear: 'both', position:'relative', marginTop: '5px' }}>
          <img src={livroReclamacoesLogo} />
      </a>
      <img src={cleanSafeLogo} style={{ float: "right", display: 'block', clear: 'both', position:'relative', marginTop: '15px' }} />
        
      <Container fluid="md">
        <Row className="mt-3">
          <Col>
            {/* <div style={{ color: "white", fontSize: 20, marginBottom: "20px", display: "inline" }}>
              {[...Array(5)].map((e, i) => (
                <FontAwesomeIcon icon={faStar} />
              ))}{" "}
            </div> */}
            <img src={bookingLogo} /> <div style={{ color: "white", fontSize: 20, display: "inline" }}>9.3</div>
            <img src={airbnbLogo} style={{ marginLeft: "10px" }} /> <div style={{ color: "white", fontSize: 20, display: "inline" }}>4.6</div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={8}>
            <span style={{ marginTop: "20px", color: "lightgray", fontSize: 20, fontStyle: "italic" }}>{t("commentPatriciaEs")}</span>
          </Col>
        </Row>
        <Row className="mt-0">
          <Col md={8}>
            <span style={{ float: "right", marginRight: "50px", color: "lightgray", fontSize: 20, fontWeight: "bold" }}>{t("patriciaEs")}</span>
          </Col>
        </Row>

        <Row className="mt-4 d-none d-md-block">
          <Col md={8}>
            <span style={{ marginTop: "30px", color: "lightgray", fontSize: 20, fontStyle: "italic" }}>{t("commentMarkUk")}</span>
          </Col>
        </Row>
        <Row className="mt-0 d-none d-md-block">
          <Col md={8}>
            <span style={{ float: "right", marginRight: "50px", color: "lightgray", fontSize: 20, fontWeight: "bold" }}>{t("markUk")}</span>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
