import React, { useState } from "react"
import { GoogleMap, LoadScript, Marker, InfoWindow } from "@react-google-maps/api"
import { useTranslation, Trans } from "react-i18next"

function Map() {
  const { t, i18n } = useTranslation()

  const [mapRef, setMapRef] = useState(null)
  const [selectedPlace, setSelectedPlace] = useState(null)
  const [markerMap, setMarkerMap] = useState({})
  const [zoom, setZoom] = useState(5)
  const [infoOpen, setInfoOpen] = useState(false)
  const blueMarker = { url: "http://maps.google.com/mapfiles/kml/paddle/blu-stars.png" }
  const yellowMarker = { url: "http://maps.google.com/mapfiles/kml/paddle/ylw-stars.png" }
  const redHomeMarker = { url: "http://maps.google.com/mapfiles/kml/paddle/red-stars.png" }

  const containerStyle = {
    width: "100%",
    height: "85vh",
    margin: 0,
    padding: 0
  }

  const feelingsLocation = {
    lat: 40.643552,
    lng: -8.648312
  }
  const canalApartmentLocation = {
    lat: 40.643671,
    lng: -8.656325
  }

  const homePlace = { id: "homePlace", pos: feelingsLocation, title: "Feelings Aveiro Apartment", info: t("yourApartmentInAveiro") }
  const canalApartment = { id: "canalApartment", pos: canalApartmentLocation, title: "Canal Apartment", info: t("canalApartmentInfo") }

  // The places I want to create markers for.
  // This could be a data-driven prop.
  const myPlaces = [
    { id: "aveiroCenter", pos: { lat: 40.641483, lng: -8.653584 }, title: t("cityCenter"), info: t("cityCenterInfo") },
    { id: "nightLife", pos: { lat: 40.642763, lng: -8.655299 }, title: t("nightLife"), info: t("nightLifeInfo") },
    { id: "trainStation", pos: { lat: 40.643993, lng: -8.641132 }, title: t("trainStation"), info: t("trainStationInfo") },
    { id: "caisFonteNova", pos: { lat: 40.639931, lng: -8.645825 }, title: t("caisFonteNova"), info: t("caisFonteNovaInfo") },
    { id: "latina", pos: { lat: 40.643795, lng: -8.648317 }, title: t("latina"), info: t("latinaInfo") },
    { id: "forum", pos: { lat: 40.64082, lng: -8.651826 }, title: t("forum"), info: t("forumInfo") },
    { id: "supermarket", pos: { lat: 40.642658, lng: -8.64764 }, title: t("supermarket"), info: t("supermarketInfo") },
    { id: "taxis", pos: { lat: 40.641828, lng: -8.652748 }, title: t("taxis"), info: t("taxisInfo") },
    { id: "mainBusStation", pos: { lat: 40.643931, lng: -8.639285 }, title: t("mainBusStation"), info: t("mainBusStationInfo") },
    { id: "beachBusStop", pos: { lat: 40.640979, lng: -8.655992 }, title: t("beachBusStop"), info: t("beachBusStopInfo") }
  ]

  // Iterate myPlaces to size, center, and zoom map to contain all markers
  const fitBounds = map => {
    const bounds = new window.google.maps.LatLngBounds()
    myPlaces.map(place => {
      bounds.extend(place.pos)
      return place.id
    })

    setTimeout(function () {
      map.fitBounds(bounds)
    }, 100)
  }

  const loadHandler = map => {
    // Store a reference to the google map instance in state
    setMapRef(map)
    // Fit map bounds to contain all markers
    fitBounds(map)
  }

  // We have to create a mapping of our places to actual Marker objects
  const markerLoadHandler = (marker, place, isHomeMarker) => {
    if (isHomeMarker) {
      markerClickHandler(marker, place)
    }

    return setMarkerMap(prevState => {
      return { ...prevState, [place.id]: marker }
    })
  }

  const markerClickHandler = (event, place) => {
    // Remember which place was clicked
    setSelectedPlace(place)

    // Required so clicking a 2nd marker works as expected
    if (infoOpen) {
      setInfoOpen(false)
    }

    setInfoOpen(true)
  }

  return (
    <LoadScript googleMapsApiKey="AIzaSyDG1jnzAve7MBqLlmv8ZBHc1BL_BjW7ycI">
      <GoogleMap mapContainerStyle={containerStyle} center={feelingsLocation} zoom={16} onLoad={loadHandler}>
        <Marker
          // The home marker.
          key={homePlace.id}
          position={homePlace.pos}
          onLoad={marker => markerLoadHandler(marker, homePlace, true)}
          onClick={event => markerClickHandler(event, homePlace)}
          icon={redHomeMarker}
        />

        <Marker
          // The canal apartment marker.
          key={canalApartment.id}
          position={canalApartment.pos}
          onLoad={marker => markerLoadHandler(marker, canalApartment)}
          onClick={event => markerClickHandler(event, canalApartment)}
          icon={blueMarker}
        />

        {myPlaces.map(place => (
          //The other markers.
          <Marker
            key={place.id}
            position={place.pos}
            onLoad={marker => markerLoadHandler(marker, place)}
            onClick={event => markerClickHandler(event, place)}
            //label={{ color: "blue", fontWeight: "bold", text: place.title.toUpperCase() }}
            // Not required, but if you want a custom icon:
            icon={yellowMarker}
          />
        ))}

        {infoOpen && selectedPlace && (
          <InfoWindow anchor={markerMap[selectedPlace.id]} onCloseClick={() => setInfoOpen(false)}>
            <div>
              <h3>{selectedPlace.title}</h3>
              <div>{selectedPlace.info}</div>
              {selectedPlace.id == "homePlace" && (
                <div>
                  <div>
                    <a target="_blank" href="http://www.airbnb.com/rooms/13918955">
                      {t("ourPageOn")}AirBnB
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="http://www.booking.com/hotel/pt/feelings-aveiro-apartment.pt-pt.html">
                      {t("ourPageOn")}Booking
                    </a>
                  </div>
                </div>
              )}

              {selectedPlace.id == "canalApartment" && (
                <div>
                  <div>
                    <a target="_blank" href="https://www.airbnb.com/rooms/25092296">
                      {t("theirPageOn")}AirBnB
                    </a>
                  </div>
                  <div>
                    <a target="_blank" href="https://www.booking.com/hotel/pt/canal-apartment.pt-pt.html">
                      {t("theirPageOn")}Booking
                    </a>
                  </div>
                </div>
              )}
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  )
}

export default Map
