import React, { useEffect } from "react"
import { useTranslation, Trans } from "react-i18next"
import { Container, Row, Col } from "react-bootstrap"
import whatsappLogo from "./Assets/whatsapp20.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding, faEnvelope, faPhone, faMapMarker } from "@fortawesome/free-solid-svg-icons"

function Contacts() {
  const { t, i18n } = useTranslation()

  return (
    <Container fluid="md">
      <Row>
        <Col lg={4} md={6}>
          <div style={{ marginTop: "30px", color: "DimGray", fontSize: 30 }}>{t("contacts")}</div>

          <div style={{ marginTop: "15px", color: "DimGray" }}>
            <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 16 }} />
            <a target="_blank" style={{ marginLeft: "5px", fontSize: 20 }} href="https://mail.google.com/mail/?view=cm&fs=1&to=aveiroenjoy@gmail.com">
              aveiroenjoy@gmail.com
            </a>
          </div>

          <div style={{ marginTop: "10px", color: "DimGray" }}>
            <FontAwesomeIcon icon={faPhone} style={{ fontSize: 16 }} />
            <a href="tel:+351-916-682-071" style={{ marginLeft: "3px", fontSize: 18 }}>
              +351 916 682 071
            </a>
          </div>

          <div style={{ marginTop: "10px", color: "DimGray" }}>
            <img src={whatsappLogo} />
            <a href="https://api.whatsapp.com/send?phone=351916682071" style={{ marginLeft: "3px", fontSize: 18 }}>
              {t("messageUsWhatsapp")}
            </a>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <div style={{ marginTop: "30px", color: "DimGray", fontSize: 30 }}>
            {/* <FontAwesomeIcon icon={faBuilding} />  */}
            {t("address")}
          </div>
          <div style={{ marginTop: "15px", color: "DimGray", fontSize: 20 }}>Rua Alberto Souto nº19</div>
          <div style={{ marginTop: "10px", color: "DimGray", fontSize: 20 }}>3800-159 Aveiro, PORTUGAL</div>
          <div style={{ marginTop: "10px", color: "DimGray" }}>
            <FontAwesomeIcon icon={faMapMarker} style={{ fontSize: 16 }} />
            <a href="https://www.google.com/maps/search/?api=1&query=40.643552,-8.648312" target="_blank" style={{ marginLeft: "3px", fontSize: 18 }}>
              Lat: 40.643552, Lon: -8.648312
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Contacts
