import React, { Suspense } from "react" // Agnostic - It can be used in mobile or DOM (browsers)
import ReactDOM from "react-dom"
import "./index.css"
import "./i18n" // Multilanguage package
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./web.config"

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
