import React, { useEffect } from "react"
import { useTranslation, Trans } from "react-i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWrench } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col } from "react-bootstrap"

function Availability() {
  const { t, i18n } = useTranslation()

  return (
    <Container>
      <Row>
        <Col>
          <div style={{ marginTop: "30px", color: "DimGray", fontSize: 30 }}>
            <FontAwesomeIcon icon={faWrench} /> {t("underConstruction")}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginTop: "15px", color: "DimGray", fontSize: 20 }}>
            {t("althoughYouCanStill")} {t("reserveHere")}
            <a target="_blank" href="http://www.airbnb.com/rooms/13918955">
              {t("feelingsAveiroApartment")}AirBnB.com
            </a>{" "}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginTop: "15px", color: "DimGray", fontSize: 20 }}>
            {t("orHere")}{" "}
            <a target="_blank" href="http://www.booking.com/hotel/pt/feelings-aveiro-apartment.pt-pt.html">
              {t("feelingsAveiroApartment")}Booking.com
            </a>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginTop: "15px", color: "DimGray", fontSize: 20 }}>
            {t("contactUsByEmail")}{" "}
            <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=aveiroenjoy@gmail.com">
              aveiroenjoy@gmail.com
            </a>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginTop: "30px", color: "DimGray", fontSize: 20 }}>{t("apartmentInformation")} </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div style={{ marginTop: "40px", color: "DimGray", fontSize: 16 }}>
            {t("searchForCanal")}
            <a target="_blank" href="https://www.booking.com/hotel/pt/canal-apartment.pt-pt.html">
              Booking.com
            </a>{" "}
            {t("or")}{" "}
            <a target="_blank" href="http://www.airbnb.com/rooms/25092296">
              AirBnB.com
            </a>{" "}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Availability
