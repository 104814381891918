import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
//import { HashRouter as Router, Route, Switch } from "react-router-dom"
import Home from "./Home"
import Availability from "./Availability"
import Photos from "./Photos"
import Map from "./Map"
import Contacts from "./Contacts"
import NoMatch from "./NoMatch"
import Layout from "./Components/Layout"
import NavigationBar from "./Components/NavigationBar"
import Jumbotron from "./Components/Jumbotron"

function App() {
  document.title = "Feelings Aveiro Apartment"

  return (
    <>
      <Router>
        <NavigationBar />
        {/* <Jumbotron /> */}
        <Layout>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/availability">
              <Availability />
            </Route>
            <Route path="/photos">
              <Photos />
            </Route>
            <Route path="/map">
              <Map />
            </Route>
            <Route path="/contacts">
              <Contacts />
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  )
}

export default App
