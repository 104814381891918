// Libraries
import React, { Component } from "react"
import { Container } from "react-bootstrap"
import ImageGallery from "react-image-gallery"
import "./Photos.css"
// CSS
import "../node_modules/react-image-gallery/styles/css/image-gallery.css"

// Living Room Images
import Salas from "./Images/SalasA.jpg"
import SalasThumb from "./Images/SalasAThumb.jpg"

import SalasB from "./Images/SalasB.jpg"
import SalasBThumb from "./Images/SalasBThumb.jpg"

import SalasC from "./Images/SalasC.jpg"
import SalasCThumb from "./Images/SalasCThumb.jpg"

import SalasD from "./Images/SalasD.jpg"
import SalasDThumb from "./Images/SalasDThumb.jpg"

import SalaDia from "./Images/SalaDia.jpg"
import SalaDiaThumb from "./Images/SalaDiaThumb.jpg"

import SalaJantar from "./Images/SalaJantar.jpg"
import SalaJantarThumb from "./Images/SalaJantarThumb.jpg"

import SalaJantarB from "./Images/SalaJantarB.jpg"
import SalaJantarBThumb from "./Images/SalaJantarBThumb.jpg"

// Balcony Images
import VarandaPortrait from "./Images/VarandaPortrait.jpg"
import VarandaPortraitThumb from "./Images/VarandaPortraitThumb.jpg"

import VarandaBPortrait from "./Images/VarandaBPortrait.jpg"
import VarandaBPortraitThumb from "./Images/VarandaBPortraitThumb.jpg"

import VarandaCPortrait from "./Images/VarandaCPortrait.jpg"
import VarandaCPortraitThumb from "./Images/VarandaCPortraitThumb.jpg"

import VarandaDPortrait from "./Images/VarandaDPortrait.jpg"
import VarandaDPortraitThumb from "./Images/VarandaDPortraitThumb.jpg"

// Kitchen Images
import Cozinha1 from "./Images/Cozinha1.jpg"
import Cozinha1Thumb from "./Images/Cozinha1Thumb.jpg"

import Cozinha2 from "./Images/Cozinha2.jpg"
import Cozinha2Thumb from "./Images/Cozinha2Thumb.jpg"

import CozinhaDetalhe1 from "./Images/CozinhaDetalhe1.jpg"
import CozinhaDetalhe1Thumb from "./Images/CozinhaDetalhe1Thumb.jpg"

import CozinhaDetalhe2 from "./Images/CozinhaDetalhe2.jpg"
import CozinhaDetalhe2Thumb from "./Images/CozinhaDetalhe2Thumb.jpg"

// Rooms Images
import Quarto1Dia from "./Images/Quarto1Dia.jpg"
import Quarto1DiaThumb from "./Images/Quarto1DiaThumb.jpg"

import Quarto1DiaB from "./Images/Quarto1DiaB.jpg"
import Quarto1DiaBThumb from "./Images/Quarto1DiaBThumb.jpg"

import Quarto1Noite from "./Images/Quarto1Noite.jpg"
import Quarto1NoiteThumb from "./Images/Quarto1NoiteThumb.jpg"

import Quarto2Noite from "./Images/Quarto2Noite.jpg"
import Quarto2NoiteThumb from "./Images/Quarto2NoiteThumb.jpg"

import Quarto3A from "./Images/Quarto3A.jpg"
import Quarto3AThumb from "./Images/Quarto3AThumb.jpg"

import Quarto3B from "./Images/Quarto3B.jpg"
import Quarto3BThumb from "./Images/Quarto3BThumb.jpg"

// WC Images
import WC1Portrait from "./Images/WC1Portrait.jpg"
import WC1PortraitThumb from "./Images/WC1PortraitThumb.jpg"

import WC2 from "./Images/WC2.jpg"
import WC2Thumb from "./Images/WC2Thumb.jpg"

// Hall Images
import HallPortrait from "./Images/HallPortrait.jpg"
import HallPortraitThumb from "./Images/HallPortraitThumb.jpg"

import BebePortrait from "./Images/BebePortrait.jpg"
import BebePortraitThumb from "./Images/BebePortraitThumb.jpg"

const images = [
  [Salas, SalasThumb],
  [SalasB, SalasBThumb],
  [SalasC, SalasCThumb],
  [SalasD, SalasDThumb],
  [SalaDia, SalaDiaThumb],
  [SalaJantar, SalaJantarThumb],
  [SalaJantarB, SalaJantarBThumb],
  [VarandaPortrait, VarandaPortraitThumb],
  [VarandaBPortrait, VarandaBPortraitThumb],
  [VarandaCPortrait, VarandaCPortraitThumb],
  [VarandaDPortrait, VarandaDPortraitThumb],
  [Cozinha1, Cozinha1Thumb],
  [Cozinha2, Cozinha2Thumb],
  [CozinhaDetalhe1, CozinhaDetalhe1Thumb],
  [CozinhaDetalhe2, CozinhaDetalhe2Thumb],
  [Quarto1Dia, Quarto1DiaThumb],
  [Quarto1DiaB, Quarto1DiaBThumb],
  [Quarto1Noite, Quarto1NoiteThumb],
  [Quarto2Noite, Quarto2NoiteThumb],
  [Quarto3A, Quarto3AThumb],
  [Quarto3B, Quarto3BThumb],
  [WC1Portrait, WC1PortraitThumb],
  [WC2, WC2Thumb],
  [HallPortrait, HallPortraitThumb],
  [BebePortrait, BebePortraitThumb]
]

const imagesForGallery = []

images.map(function (imageCouple) {
  imagesForGallery.push({
    original: imageCouple[0],
    thumbnail: imageCouple[1],
    originalClass: imageCouple[0].includes("Portrait") ? "portrait-slide" : "",
    thumbnailClass: imageCouple[1].includes("Portrait") ? "portrait-thumb" : ""
  })
})

function Photos() {
  return (
    <Container>
      <ImageGallery items={imagesForGallery} />
    </Container>
  )
}

export default Photos
