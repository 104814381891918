import React from "react"
import { Jumbotron as Jumbo, Container } from "react-bootstrap"
import styled from "styled-components"
import aveiroImage from "../Assets/aveiroimage.jpg"

const Styles = styled.div`
  .jumbo {
    background: url(${aveiroImage}) no-repeat bottom;
    background-size: cover;
    color: #ccc;
    height: 100px;
    position: relative;
    z-index: -2;
  }

  .overlay {
    background-color: #000;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
`

function Jumbotron() {
  return (
    <Styles>
      <Jumbo fluid className="jumbo">
        <div className="overlay" />
        <Container>
          <h1>Welcome</h1>
          <p>Enjoy your stay in our beautiful city full of wonderful surprises !</p>
        </Container>
      </Jumbo>
    </Styles>
  )
}

export default Jumbotron
