import React, { useEffect } from "react"

function NoMatch() {
  return (
    <>
      <div>404 : The requested page doesn't exist</div>
    </>
  )
}

export default NoMatch
